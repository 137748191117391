import moment from "moment";
import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DrawerModal } from "../../components/DrawerModal";
import {
  Button as AntButton,
  Col,
  List,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { Button as MuiButton } from "@mui/material";
import { formatCurrency } from "../../utils";
import { Container, Content } from "./styles";
import { FormModal } from "../../components/FormModal";
import { TransferenciaCustodiaRows } from "./formItems";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { useTransferenciaCustodia } from "../../contexts/TransferenciaCustodiaContext";
import { useNotasCorretagem } from "../IntegrationBolsa/NotasCorretagem/useNotasCorretagem";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import UploadVelotax from "../../components/UploadVelotax";

interface TransferenciaCustodiaProps {}

export const TransferenciaCustodia: React.FC<
  TransferenciaCustodiaProps
> = () => {
  const [showNotasCorretagemModal, setShowNotasCorretagemModal] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const navigate = useNavigate();
  const {
    add,
    edit,
    remove,
    ativo,
    ativos,
    loading,
    handleAdd,
    handleEdit,
    handleRemove,
    showFormModal,
    showDeleteModal,
    closeFormModal,
    closeDeleteModal,
    saveAtivos,
    isInvalidItem,
  } = useTransferenciaCustodia();

  const { sendNotasCustodyTransfer, uploadProps } = useNotasCorretagem();


  return (
    <Container>
      <Typography.Title level={1}>Transferência de custódia</Typography.Title>
      <Typography.Paragraph>
        Insira manualmente as informações do seu histórico de operação para os
        ativos em que foi realizada a transferência de custódia. A inserção
        manual é necessária pois não possuímos acesso às informações do seu
        histórico transacionado em outra corretora.
      </Typography.Paragraph>
      <Content>
        <div className="container-buttons">
          <Button
            className="add-button"
            variant="contained"
            color="secondary"
            onClick={() => setShowNotasCorretagemModal(true)}
          >
            Importar nota de corretagem
          </Button>
          <Button
            className="add-button"
            variant="contained"
            color="secondary"
            onClick={handleAdd}
          >
            Adicionar ativo
          </Button>
        </div>

        <List
          dataSource={ativos}
          loading={loading.get}
          itemLayout="horizontal"
          renderItem={(item, index) => (
            <List.Item
              className={isInvalidItem(item) ? "error-border" : ""}
              actions={[
                ...(isInvalidItem(item)
                  ? [
                      <Tooltip
                        title={`Preencha ${
                          !item.dataCompra ? "a data" : "o valor"
                        } de compra!`}
                      >
                        <AntButton
                          type="text"
                          icon={
                            <AiOutlineInfoCircle
                              className="alert-icon"
                              size={20}
                            />
                          }
                        />
                      </Tooltip>,
                    ]
                  : []),
                <AntButton
                  type="text"
                  disabled={loading.put}
                  icon={<FaRegEdit size={20} />}
                  onClick={() => handleEdit(item, index)}
                />,
                <AntButton
                  type="text"
                  disabled={loading.delete}
                  icon={<AiOutlineDelete size={20} />}
                  onClick={() => handleRemove(item, index)}
                />,
              ]}
            >
              <Skeleton active title={false} loading={loading.get}>
                <List.Item.Meta
                  title={<div>{item.codigo}</div>}
                  description={
                    <>
                      <div className="list-description">
                        <div>
                          Quantidade: <strong>{item.quantidade}</strong>
                        </div>
                        {item.dataCompra && (
                          <div>
                            Data de compra:{" "}
                            <strong>
                              {item.dataCompra.includes("/")
                                ? item.dataCompra
                                : moment
                                    .utc(item.dataCompra)
                                    ?.format("DD/MM/YYYY")}
                            </strong>
                          </div>
                        )}
                        <div>
                          Valor de compra (USD, Origem BRA):{" "}
                          <strong>
                            {formatCurrency(item.valorCompraUSDOrigemBRA ?? 0, "USD")}
                          </strong>
                        </div>
                        <div>
                          Valor de compra (USD, Origem EUA):{" "}
                          <strong>
                            {formatCurrency(item.valorCompraUSDOrigemEUA ?? 0, "USD")}
                          </strong>
                        </div>
                      </div>
                    </>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <Typography style={{ marginTop: "32px", textAlign: "right" }}>
          Lembre-se de clicar em finalizar para salvar seus dados
        </Typography>
        <Row
          align="middle"
          gutter={[16, 16]}
          justify="space-between"
          style={{
            marginTop: "1.5rem",
          }}
        >
          <Col>
            <Button
              size="large"
              variant="text"
              color="secondary"
              className="back-button"
              onClick={() => {
                navigate("/xpinvestimentos/exterior");
              }}
            >
              Voltar
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              className="final-button"
              onClick={saveAtivos}
              endIcon={<AiOutlineCheck size={18} />}
            >
              Finalizar
            </Button>
          </Col>
        </Row>
        <FormModal
          add={(at: any) => add({ ...at, orderId: ativo?.orderId })}
          edit={(at: any) => edit({ ...at, orderId: ativo?.orderId })}
          itemToEdit={ativo}
          onCancel={closeFormModal}
          visibility={showFormModal}
          rows={TransferenciaCustodiaRows(!!ativo?.id)}
          title={ativo ? "Editar ativo" : "Adicionar ativo"}
        />
        <DeleteConfirmationModal
          title="Remover ativo"
          onCancel={closeDeleteModal}
          visibility={showDeleteModal}
          setVisibility={() => closeDeleteModal()}
          onOk={() => Promise.resolve(remove(ativo!))}
          body="Você realmente quer deletar esse ativo?"
        />
        <DrawerModal
          footer={null}
          visible={showNotasCorretagemModal}
          title="Carregar notas de corretagem"
          onCancel={() => {
            if (!loadingSend) {
              setShowNotasCorretagemModal(false);
            }
          }}
        >
          <Typography.Paragraph>
            <InfoCircleOutlined /> Você pode carregar as notas de corretagem para trazer automaticamente suas transferencias de custódia
          </Typography.Paragraph>
          <UploadVelotax loading={loadingSend} {...uploadProps} />
          <Row justify="end">
            <Col>
              <MuiButton
                size="large"
                color="secondary"
                variant="contained"
                disabled={loadingSend}
                onClick={() => sendNotasCustodyTransfer(setLoadingSend, setShowNotasCorretagemModal)}
              >
                Finalizar
              </MuiButton>
            </Col>
          </Row>
        </DrawerModal>
      </Content>
    </Container>
  );
};
