import { UserPlanEnum } from "../constants/plans";
import apiBolsa from "../services/apiBolsa";

export const B3AuthURL =
  "https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=069ecfdf-7471-48eb-be82-d50799c99e5c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login";

export const getQuizLabel = (username: string, message: string): string => {
  return username !== "Declarante"
    ? `${username} ${message}`
    : `Você ${message}`;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

const iOSList = [
  "iPad Simulator",
  "iPhone Simulator",
  "iPod Simulator",
  "iPad",
  "iPhone",
  "iPod",
];

export const isIOS = () =>
  iOSList.includes(navigator.platform) ||
  iOSList.includes((navigator as any).userAgentData?.platform) ||
  navigator.userAgent.includes("Mac") ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const isIOSWebView = isIOS() && !isSafari;

// export const isAndroidWebView = ?????

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const isExternalLink = (link: string): boolean => {
  return /http:\/\/|https:\/\//i.test(link);
};

export const errorMessage =
  "Algo inesperado aconteceu! Tente novamente mais tarde.";

export const tenteIntegrarNovamente = "Tente integrar novamente.";

export const reintegrateMessage =
  "Faça novamente a integração para buscar dados mais novos";

export const download = (link: string) => {
  window.location.href = link;
};

export const downloadPDF = (base64: string, fileName?: string) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  fileName = fileName ?? `Relatório-${Date.now()}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getMuiInputCss = () =>
  document
    .querySelector("input.MuiInputBase-input")
    ?.classList.value.split(" ")
    .find((c) => c.includes("css")) ?? "";


export const planInfoToHasPlan = (planInfo: any, hasPermissionGeneral: any) => 
  (!!planInfo?.type && !!planInfo?.active) || !!hasPermissionGeneral
    ? {
      basic: !!planInfo?.type || !!hasPermissionGeneral,
      premium: [
        UserPlanEnum.XP_PRO,
        UserPlanEnum.XP_PRO_MONTH,
        UserPlanEnum.XP_CONCIERGE,
        UserPlanEnum.VELOTAX_MAIN_PRO,
        UserPlanEnum.VELOTAX_MAIN_PRO_MONTH,
        UserPlanEnum.XP_CONCIERGE
      ].includes(planInfo?.type) || !!hasPermissionGeneral,
      concierge: [UserPlanEnum.XP_CONCIERGE, UserPlanEnum.VELOTAX_MAIN_CONCIERGE].includes(planInfo?.type),
    }
    : false;

export const getZeroBuyPrice = async (data: any) => {
  let hasZero: any[] = [];
  const donations = await apiBolsa.get("/operation/donations?operationType=Credito", {});
  const pos2019 = await apiBolsa.get("/operation?month=12&year=2019", {});

  for (const element of data) {
    const code = element?.code;
    const isTaxa = (code.toLowerCase()).includes('taxa');
    if (isTaxa) continue;
    const market = element?.market;
    const buyPrice = element?.buyPrice;

    if (market.includes('opções') || market.includes('Opção')) continue;
    const reasonDictionary = [
      { text: "Preencha o custo de compra do ativo na aba", textLink: "Doações e heranças", link: "/xpinvestimentos/bolsa-doacoes-heranca" },
      { text: "Preencha o custo de compra do ativo na aba", textLink: "Posição 31/12/2019", link: "/xpinvestimentos/bolsa-insert-manual" }
    ];
    const hasDonations: any[] = [];
    donations?.data?.filter((op: any) => {
      op?.orders?.filter((ord: any) => {
        if (code === ord?.code) {
          hasDonations.push(ord);
          return;
        }
        else return;
      })
    })
    const hasPos2019: any[] = [];
    pos2019?.data?.filter((op: any) => {
      op?.orders?.filter((ord: any) => {
        if (code === ord?.code) {
          hasPos2019.push(ord);
          return;
        }
        else return;
      })
    })
    const reason = hasDonations.length ? 0 : (hasPos2019.length ? 1 : "");
    // Para quando o ativo não estiver zerado no mês, mas estiver zerado em doações e heranças
    if (hasDonations.length > 0 && hasDonations[0]?.price == 0) {
      hasZero.push({ code: code, reason: reason !== "" ? reasonDictionary[reason] : "" });
    }
    else if ((buyPrice == 0 || !buyPrice)
      && ((hasDonations.length > 0 && hasDonations[0]?.price == 0)
        || (hasPos2019.length > 0 && hasPos2019[0]?.price == 0))) {
      hasZero.push({ code: code, reason: reason !== "" ? reasonDictionary[reason] : "" });
    }
    else if ((buyPrice == 0 || !buyPrice) && hasDonations.length == 0 && hasPos2019.length == 0) {
      hasZero.push({ code: code, reason: "" });
    }
  }
  let elementsRepeat: string[] = [];
  hasZero = hasZero.filter((item) => {
    if (!elementsRepeat.includes(item.code)) {
      elementsRepeat.push(item.code);
      return item;
    }
    else return;
  });

  return hasZero;
}